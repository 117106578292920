<template>
  <button :class="{primary: type == 'primary', secondary: type == 'secondary'}">
    <slot></slot>
    {{ text }}
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    text: {
      type: String,
      default: "Button",
    },
    type: {
      type: String,
      default: "primary",
    }
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
<style scoped lang="scss">
// primary
.primary {
  transition: background-color 0.2s;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  line-height: 32px;
  padding: 0 12px;
  color: var(--white);
  background-color: var(--main_5);
  &:hover {
    background-color: var(--main_6);
  }
  &:active {
    background-color: var(--main_7);
  }
  &:focus {
    outline: none;
  }
}
// secondary
.secondary {
  transition: background-color 0.2s;
  font-size: 14px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  line-height: 32px;
  padding: 0 12px;
  color: var(--main_5);
  background-color: var(--grey_1);
  &:hover {
    color: var(--main_6);
    background-color: var(--grey_2);
  }
  &:active {
    color: var(--main_7);
    background-color: var(--grey_3);
  }
  &:focus {
    outline: none;
  }
}
</style>