<template>
  <div class="pagination">
      <div>
      <router-link v-if=" pre != '' " :to="prePath">{{ '上一篇：' + pre }}</router-link>
      </div>
      <div>
      <router-link v-if=" next != '' " :to="nextPath">{{ '下一篇：' + next }}</router-link>
      </div>
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    width: {
      type: String,
      default: "100%",
    },
    pre: {
      type: String,
      default: "",
    },
    next: {
      type: String,
      default: "",
    },
    prePath: {
      type: String,
      default: "/",
    },
    nextPath: {
      type: String,
      default: "/",
    },
  },
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>
<style scoped lang="scss">
a {
    font-size: 14px;
    transition: color 0.2s;
    color: var(--grey_3);
    &:hover {
        color: var(--main_5);
    }
}
.pagination {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
}
</style>