<template>
  <SideMenu :tree="tree"></SideMenu>
  <ThemeSwitch></ThemeSwitch>
  <div class="container">
    <router-view />
  </div>
</template>

<script>
import SideMenu from "@/components/SideMenu.vue";
import ThemeSwitch from "@/components/ThemeSwitch.vue";

export default {
  components: {
    SideMenu,
    ThemeSwitch,
  },
  data() {
    return {
      tree: {
        首页: {
          path: "/",
        },
        基础: {
          show: false,
          child: {
            '元素显示方式': {
              path: "/display",
            },
            'CSS 盒模型': {
              path: "/box_model",
            },
            'CSS 选择器': {
              path: "/selector",
            },
            '元素定位': {
              show: false,
              path: "/position",
            },
          },
        },
        布局: {
          show: false,
          child: {
            Flexbox: {
              show: false,
              path: "/flex_box",
            },
            Grid: {
              show: false,
              path: "/grid",
            },
            自适应布局: {
              show: false,
              path: "/self_adaptive_layout",
            },
            响应式布局: {
              show: false,
              path: "/responsive_layout",
            },
          },
        },
        样式: {
          show: false,
          child: {
            颜色: {
              path: "/color",
            },
            边框: {
              path: "/border",
            },
            轮廓: {
              path: "/outline",
            },
          },
        },
        效果: {
          show: false,
          child: {
            阴影: {
              path: "/shadow",
            },
            滤镜: {
              show: false,
              path: "/filter",
            },
          },
        },
        文字: {
          path: "/word",
          show: false,
          child: {
            文本: {
              child: {
                对齐: {
                  path: "/text_align",
                },
                颜色: {
                  path: "/text_color",
                },
              },
            },
            字体: {
              path: "/font",
            },
          },
        },
        动画: {
          path: "/animation",
        },
      },
    };
  },
};
</script>

<style lang="scss">
@import "./theme/article.scss";
@import "./theme/lisa.css";
@import "./theme/lisa_mirror.css";

* {
  box-sizing: border-box;
}
.container {
  margin-left: 190px;
  > div {
    max-width: 960px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 200px;
  }
}
body {
  margin: 0;
  background-color: var(--white);
  font-family: sans-serif;
}
</style>

<style lang="scss">
  // mobile
  @media screen and (max-width: 720px) {
    .container {
      margin-left: 0;
    }
  }
</style>