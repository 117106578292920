<template>
  <div class="coding" :class="{ full_screen: isFullScreen }">
    <!-- modal -->
    <Modal
      :display="resetCodeModalDisplay"
      message="重置代码后，编辑过的代码将无法恢复，是否确认重置？"
    >
      <Button text="确认" @click="resetCode"></Button>
      <Button
        type="secondary"
        text="取消"
        @click="resetCodeModalSwitch"
      ></Button>
    </Modal>
    <div
      class="coding_header"
      :style="{ opacity: isFullScreen == true ? 1 : '' }"
    >
      <div @click="undo">
        <Tooltip>撤销</Tooltip>
        <CornerUpLeftIcon size="1.2x"></CornerUpLeftIcon>
      </div>
      <div @click="redo">
        <Tooltip>恢复</Tooltip>
        <CornerUpRightIcon size="1.2x"></CornerUpRightIcon>
      </div>
      <div @click="wrapCodeSwitch">
        <Tooltip v-if="wrapCode == false">允许代码换行</Tooltip>
        <Tooltip v-if="wrapCode">禁止代码换行</Tooltip>
        <AlignLeftIcon size="1.2x" v-if="wrapCode == false"></AlignLeftIcon>
        <AlignJustifyIcon size="1.2x" v-if="wrapCode"></AlignJustifyIcon>
      </div>
      <div @click="codeEditorThemeSwitch">
        <Tooltip v-if="codeEditorTheme == 'light'">深色主题</Tooltip>
        <Tooltip v-if="codeEditorTheme == 'dark'">明亮主题</Tooltip>
        <SunIcon size="1.2x" v-if="codeEditorTheme == 'dark'"></SunIcon>
        <MoonIcon size="1.2x" v-if="codeEditorTheme == 'light'"></MoonIcon>
      </div>
      <div @click="fontSizeSelect">
        <Tooltip>字体大小 {{ fontSize }}</Tooltip>
        <TypeIcon size="1.2x"></TypeIcon>
      </div>
      <div @click="fullScreen">
        <Tooltip v-if="isFullScreen == false">全屏显示</Tooltip>
        <Tooltip v-if="isFullScreen == true">取消全屏</Tooltip>
        <MaximizeIcon size="1.2x" v-if="isFullScreen == false"></MaximizeIcon>
        <MinimizeIcon size="1.2x" v-if="isFullScreen == true"></MinimizeIcon>
      </div>
      <div @click="resetCodeModalSwitch">
        <Tooltip>重置代码</Tooltip>
        <RotateCcwIcon size="1.2x"></RotateCcwIcon>
      </div>
    </div>
    <div class="tab">
      <div :class="{ tab_selected: isLeft }" @click="switchView">CSS</div>
      <div :class="{ tab_selected: isLeft == false }" @click="switchView">
        HTML
      </div>
    </div>
    <!-- coding -->
    <ResizeRow
      :width="isFullScreen == true ? '100%' : 'calc(100% + 20px)'"
      :height="height"
      :isFullScreen="isFullScreen"
      :class="{ is_left: isLeft, is_right: isLeft == false }"
      @isDrag="getDragerState"
      @isNotDrag="getDragerState"
    >
      <DragerRow
        height="100%"
        width="100%"
        :topPercent="topPercent"
        @isDrag="getDragerState"
        @isNotDrag="getDragerState"
      >
        <template #top>
          <DragerCol
            :leftPercent="topLeftPercent"
            height="100%"
            width="100%"
            :style="{ paddingRight: isFullScreen == true ? '' : '20px' }"
            @isDrag="getDragerState"
            @isNotDrag="getDragerState"
          >
            <template #left>
              <CodeEditor
                :font_size="fontSize"
                v-model="cssData"
                :languages="[['css', 'CSS']]"
                :theme="codeEditorTheme"
                width="100%"
                height="100%"
                border_radius="8px"
                :wrap_code="wrapCode"
                :style="{ pointerEvents: isDrag == true ? 'none' : '' }"
              ></CodeEditor>
            </template>
            <template #right>
              <CodeEditor
                :font_size="fontSize"
                v-model="htmlData"
                :languages="[['html', 'HTML']]"
                :theme="codeEditorTheme"
                width="100%"
                height="100%"
                border_radius="8px"
                :wrap_code="wrapCode"
                :style="{ pointerEvents: isDrag == true ? 'none' : '' }"
              ></CodeEditor>
            </template>
          </DragerCol>
        </template>
        <template #down>
          <DragerCol
            height="100%"
            width="100%"
            :leftPercent="downLeftPercent"
            @isDrag="getDragerState"
            @isNotDrag="getDragerState"
          >
            <template #left>
              <iframe
                :src="iframeSrc"
                @load="onLoadIframe"
                ref="iframe"
                :style="{ pointerEvents: isDrag == true ? 'none' : '' }"
              ></iframe>
            </template>
          </DragerCol>
        </template>
      </DragerRow>
    </ResizeRow>
  </div>
</template>
<script>
import CodeEditor from "simple-code-editor";
import Tooltip from "@/components/Tooltip";
import Modal from "@/components/Modal";
import Button from "@/components/Button";
// coding
import ResizeRow from "@/components/ResizeRow";
import DragerCol from "@/components/DragerCol";
import DragerRow from "@/components/DragerRow";
// icon
import {
  AlignLeftIcon,
  SunIcon,
  TypeIcon,
  CornerUpLeftIcon,
  CornerUpRightIcon,
  RotateCcwIcon,
  AlignJustifyIcon,
  MoonIcon,
  MaximizeIcon,
  MinimizeIcon,
} from "@zhuowenli/vue-feather-icons";

export default {
  name: "Coding",
  components: {
    CodeEditor,
    AlignLeftIcon,
    SunIcon,
    TypeIcon,
    CornerUpLeftIcon,
    CornerUpRightIcon,
    RotateCcwIcon,
    Tooltip,
    AlignJustifyIcon,
    MoonIcon,
    MaximizeIcon,
    MinimizeIcon,
    Modal,
    Button,
    // coding
    ResizeRow,
    DragerCol,
    DragerRow,
  },
  props: {
    height: {
      // 总高度
      type: Number,
      default: 500,
    },
    topPercent: {
      // 上模块高度百分比
      type: Number,
      default: 60,
    },
    topLeftPercent: {
      // 上模块左半部分宽度百分比
      type: Number,
      default: 50,
    },
    downLeftPercent: {
      // 下模块左半部分宽度百分比
      type: Number,
      default: 100,
    },
    iframeSrc: {
      type: String,
      default: "./iframes/iframe.html",
    },
    css: {
      type: String,
      default: `body {
    text-align: center;
    background: #eee;
    height: 100vh;
    margin: 0;
}
h1 {
    position: relative;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
}`,
    },
    html: {
      type: String,
      default: `<h1>Hello, World!</h1>`,
    },
  },
  data() {
    return {
      fontSize: "17px",
      wrapCode: false,
      codeEditorTheme: "dark",
      cssData: this.css,
      htmlData: this.html,
      resetConfirm: false,
      iframeDom: {},
      resetCodeModalDisplay: false,
      isFullScreen: false,
      isLeft: true,
      isDrag: false,
    };
  },
  watch: {
    // 监听 cssData 数据的变化，当编辑器内 css 发生改变后执行函数
    cssData() {
      this.sendDataToIframeAndUpdateStyle();
    },
    // 监听 htmlData 数据的变化，当编辑器内 html 发生改变后执行函数
    htmlData() {
      this.sendDataToIframeAndUpdateDom();
    },
  },
  methods: {
    // 接收子组件发给父组件是否开始拖拽状态的数据，子组件传递数据时会自动触发
    getDragerState(data) {
      this.isDrag = data;
    },
    // vue 页面向 iframe 内部传数据更新样式的函数
    sendDataToIframeAndUpdateStyle() {
      this.iframeDom.postMessage({
        css: this.cssData,
      });
    },
    // vue 页面向 iframe 内部传数据更新 Dom 的函数
    sendDataToIframeAndUpdateDom() {
      this.iframeDom.postMessage({
        html: this.htmlData,
      });
    },
    // 接收来自 iframe 数据时候的执行函数
    // receiveDataFromIframe(event) {
    //   // console.log(event.data.data);
    // },
    // 当 iframe 加载成功后发送 css 和 html 数据
    onLoadIframe() {
      this.sendDataToIframeAndUpdateStyle();
      this.sendDataToIframeAndUpdateDom();
    },
    // 编辑器工具栏操作
    undo() {
      document.execCommand("undo", false, null);
    },
    redo() {
      document.execCommand("redo", false, null);
    },
    wrapCodeSwitch() {
      this.wrapCode == true
        ? ((this.wrapCode = false),
          localStorage.setItem("code_editor_wrap_code", "0"))
        : ((this.wrapCode = true),
          localStorage.setItem("code_editor_wrap_code", "1"));
    },
    codeEditorThemeSwitch() {
      this.codeEditorTheme == "dark"
        ? ((this.codeEditorTheme = "light"),
          document.body.setAttribute("code_theme", "light"),
          localStorage.setItem("code_editor_theme", "light"))
        : ((this.codeEditorTheme = "dark"),
          document.body.setAttribute("code_theme", "dark"),
          localStorage.setItem("code_editor_theme", "dark"));
      // location.reload();
    },
    fontSizeSelect() {
      const fontSize = parseInt(this.fontSize);
      fontSize > 27
        ? ((this.fontSize = "13px"),
          localStorage.setItem("code_editor_font_size", "13px"))
        : ((this.fontSize = fontSize + 2 + "px"),
          localStorage.setItem("code_editor_font_size", fontSize + 2 + "px"));
    },
    resetCodeModalSwitch() {
      this.resetCodeModalDisplay == true
        ? (this.resetCodeModalDisplay = false)
        : (this.resetCodeModalDisplay = true);
    },
    resetCode() {
      this.cssData = this.css;
      this.htmlData = this.html;
      this.resetCodeModalSwitch();
    },
    fullScreen() {
      if (this.isFullScreen == true) {
        this.isFullScreen = false;
        document.body.style.overflow = "";
      } else {
        this.isFullScreen = true;
        document.body.style.overflow = "hidden";
      }
    },
    switchView() {
      this.isLeft = this.isLeft == true ? false : true;
    },
  },
  mounted() {
    // 页面加载完成后，监听 message 的状态，一旦发生改变执行函数 receiveDataFromIframe
    // window.addEventListener("message", this.receiveDataFromIframe);
    this.iframeDom = this.$refs.iframe.contentWindow;
    // 编辑器初次加载上一次的主题
    if (localStorage.getItem("code_editor_theme")) {
      localStorage.getItem("code_editor_theme") == "light"
        ? ((this.codeEditorTheme = "light"),
          document.body.setAttribute("code_theme", "light"))
        : ((this.codeEditorTheme = "dark"),
          document.body.setAttribute("code_theme", "dark"));
    }
    // 编辑器初次加载上一次的字体
    if (localStorage.getItem("code_editor_font_size")) {
      this.fontSize = localStorage.getItem("code_editor_font_size");
    }
    // 编辑器初次加载上一次折行配置
    if (localStorage.getItem("code_editor_wrap_code")) {
      const isTrue =
        localStorage.getItem("code_editor_wrap_code") == 1 ? true : false;
      this.wrapCode = isTrue;
    }
  },
};
</script>
<style scoped lang="scss">
.full_screen {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100vh;
}
.coding {
  position: relative;
  background-color: var(--white);
  &:hover {
    .coding_header {
      opacity: 1;
    }
  }
  iframe {
    height: 100%;
    width: 100%;
    border-width: 0;
  }
}
.coding_header {
  transition: opacity 0.2s;
  opacity: 0;
  display: flex;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2px;
  > div {
    margin-right: 24px;
  }
  > div {
    position: relative;
    transition: background-color 0.2s, color 0.2s;
    cursor: pointer;
    border-radius: 6px;
    padding: 8px;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--grey_4);
    &:hover {
      background-color: var(--grey_1);
      color: var(--main_5);
      > .tooltip {
        display: block;
      }
    }
    &:active {
      background-color: var(--grey_2);
      color: var(--main_6);
    }
  }
}
// tab
.tab {
  height: 28px;
  width: 100%;
  border-radius: 6px;
  background-color: var(--grey_0);
  margin-bottom: 5px;
  padding: 3px;
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3px;
}
.tab > div {
  user-select: none;
  text-align: center;
  line-height: 22px;
  height: 100%;
  border-radius: 3px;
  font-size: 12px;
  color: var(--grey_5);
}
.tab_selected {
  color: white !important;
  background-color: var(--grey_2);
}
</style>

<style lang="scss">
// mobile
@media screen and (max-width: 720px) {
  .coding_header {
    opacity: 1 !important;
    > div {
      margin-right: 4px !important;
    }
  }
  .tab {
    display: grid !important;
  }
  .resize_row {
    margin-right: -20px;
  }
  .is_left {
    .drager_top {
      .drager_left {
        padding-right: 0 !important;
        width: 100% !important;
      }
      .col_slider {
        display: none !important;
      }
      .drager_right {
        display: none !important;
      }
    }
  }
  .is_right {
    .drager_top {
      .drager_right {
        padding-left: 0 !important;
        width: 100% !important;
      }
      .col_slider {
        display: none !important;
      }
      .drager_left {
        display: none !important;
      }
    }
  }
}
</style>