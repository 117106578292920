<template>
  <div class="tooltip">
      <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Tooltip",
  props: {
    width: {
      type: String,
      default: "100%",
    },
  },
};
</script>
<style scoped lang="scss">
.tooltip {
    display: none;
    position: absolute;
    z-index: 5;
    white-space: nowrap;
    padding: 5px 8px;
    background-color: rgba(0,0,0,0.66);
    color: white;
    font-size: 13px;
    border-radius: 4px;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
}
</style>