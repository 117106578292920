<template>
  <div
    class="drager_row"
    ref="container"
    :style="{ width: width, height: height }"
  >
    <div class="drager_top" :style="{ height: top + '%' }">
      <div>
        <slot name="top"></slot>
      </div>
    </div>
    <div
      class="row_slider"
      @touchstart.passive="mobileDragRow"
      @mousedown="dragRow"
    ></div>
    <div class="drager_down" :style="{ height: 100 - top + '%' }">
      <div>
        <slot name="down"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Drager",
  props: {
    topPercent: {
      type: Number,
      default: 50,
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "600px",
    },
  },
  data() {
    return {
      top: this.topPercent,
      isDrag: false,
    };
  },
  methods: {
    // 上下滑动
    mobileDragRow(e) {
      document.body.style.overflow = "hidden"; // 手机上下滑动禁止页面滚动
      document.body.style.overscrollBehaviorY = "contain"; // 手机下滑禁止页面刷新
      e = e || window.event;
      e.stopPropagation();
      let oldPos = e.changedTouches[0].clientY;
      let oldPosPercent = this.top;
      let newPos = 0;
      let newPosPercent = 0;
      const containerHeight = this.$refs.container.offsetHeight;
      const vue = this;
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 鼠标移动和抬起时触发
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientY;
        const maxHeight =
          parseFloat(((1 - 10 / containerHeight) * 100).toFixed(3)) + 0.001;
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerHeight) * 100).toFixed(3)
        );
        newPosPercent = oldPosPercent - movingDistancePercent;
        if (newPosPercent <= 0) {
          vue.top = 0;
        } else if (newPosPercent >= maxHeight) {
          vue.top = maxHeight;
        } else {
          vue.top = newPosPercent;
        }
      }
      // 抬起触发函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag);
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorY = "";
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    },
    dragRow(e) {
      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      let oldPos = e.clientY;
      let oldPosPercent = this.top;
      let newPos = 0;
      let newPosPercent = 0;
      const containerHeight = this.$refs.container.offsetHeight;
      const vue = this;
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 鼠标移动和抬起时触发
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.clientY;
        const maxHeight =
          parseFloat(((1 - 10 / containerHeight) * 100).toFixed(3)) + 0.001;
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerHeight) * 100).toFixed(3)
        );
        newPosPercent = oldPosPercent - movingDistancePercent;
        if (newPosPercent <= 0) {
          vue.top = 0;
        } else if (newPosPercent >= maxHeight) {
          vue.top = maxHeight;
        } else {
          vue.top = newPosPercent;
        }
      }
      // 抬起触发函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag);
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drager_row {
  overflow: hidden;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  > div {
    width: 100%;
  }
}
.row_slider {
  transition: background-color 0.2s;
  margin: -10px 0;
  position: relative;
  cursor: row-resize;
  height: 20px;
  &:before {
    transition: background-color 0.2s;
    position: absolute;
    left: 50%;
    top: 6px;
    margin-left: -30px;
    content: "";
    display: block;
    height: 1px;
    width: 60px;
    background-color: var(--grey_3);
  }
  &:after {
    transition: background-color 0.2s;
    position: absolute;
    left: 50%;
    bottom: 6px;
    margin-left: -30px;
    content: "";
    display: block;
    height: 1px;
    width: 60px;
    background-color: var(--grey_3);
  }
  &:active,
  &:hover {
    background-color: var(--main_1);
    &:before,
    &:after {
      background-color: var(--main_6);
    }
  }
}
.drager_top {
  padding-bottom: 10px;
  div {
    height: 100%;
    overflow: hidden;
  }
}
.drager_down {
  padding-top: 10px;
  div {
    height: 100%;
    overflow: hidden;
  }
}
</style>