<template>
  <div class="resize_row" :style="{ height: isFullScreen == true ?  'calc(100% - 17px)' : reHeight + 'px', width: width }">
    <div class="resize_row_body">
      <slot></slot>
    </div>
    <div
      class="slider_row"
      @touchstart.passive="mobileResizeRow"
      @mousedown="resizeRow"
    ></div>
  </div>
</template>
<script>
export default {
  name: "ResizeRow",
  props: {
    height: {
      type: Number,
      default: 600,
    },
    width: {
      type: String,
      default: "600px",
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      reHeight: this.height,
      isDrag: false,
    };
  },
  methods: {
    // 上下缩放
    mobileResizeRow(e) {
      document.body.style.overflow = "hidden"; // 手机上下滑动禁止页面滚动
      document.body.style.overscrollBehaviorY = "contain"; // 手机下滑禁止页面刷新
      e = e || window.event;
      e.stopPropagation();
      let oldPos = e.changedTouches[0].clientY;
      let oldHeight = this.reHeight;
      let newPos = 0;
      let newHeight = 0;
      const vue = this;
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 鼠标移动和抬起时触发
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientY;
        const movingDistance = oldPos - newPos;
        newHeight = oldHeight - movingDistance;
        if (newHeight <= 20) {
          vue.reHeight = 20;
        } else {
          vue.reHeight = newHeight;
        }
      }
      // 抬起触发函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag);
        document.body.style.overflow = "";
        document.body.style.overscrollBehaviorY = "";
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    },
    resizeRow(e) {
      e = e || window.event;
      e.preventDefault();
      e.stopPropagation();
      let oldPos = e.clientY;
      let oldHeight = this.reHeight;
      let newPos = 0;
      let newHeight = 0;
      const vue = this;
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 鼠标移动和抬起时触发
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        newPos = e.clientY;
        const movingDistance = oldPos - newPos;
        newHeight = oldHeight - movingDistance;
        if (newHeight <= 20) {
          vue.reHeight = 20;
        } else {
          vue.reHeight = newHeight;
        }
      }
      // 抬起触发函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag);
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.resize_row {
  overflow: hidden;
  box-sizing: border-box;
  box-sizing: border-box;
  padding-bottom: 20px;
  * {
    box-sizing: border-box;
  }
}
.resize_row_body {
  width: 100%;
  height: 100%;
}
.slider_row {
  transition: background-color 0.2s;
  position: relative;
  cursor: row-resize;
  margin-bottom: -20px;
  height: 20px;
  &:before {
    transition: background-color 0.2s;
    position: absolute;
    left: 50%;
    top: 6px;
    margin-left: -30px;
    content: "";
    display: block;
    height: 1px;
    width: 60px;
    background-color: var(--grey_3);
  }
  &:after {
    transition: background-color 0.2s;
    position: absolute;
    left: 50%;
    bottom: 6px;
    margin-left: -30px;
    content: "";
    display: block;
    height: 1px;
    width: 60px;
    background-color: var(--grey_3);
  }
  &:hover,
  &:active {
    background-color: var(--main_1);
    &:before,
    &:after {
      background-color: var(--main_6);
    }
  }
}
</style>