<template>
  <div class="modal" v-if="display == show">
      <div class="modal_container" :style="{width: width, maxWidth: maxWidth, minWidth: minWidth}">
          <div class="modal_header">
              <div class="title">提示</div>
              <div class="close_button" @click="close">
                <XIcon size="1.2x"></XIcon>
              </div>
          </div>
          <div class="modal_body">
            {{ message }}
          </div>
          <div class="modal_footer">
            <slot></slot>
          </div>
      </div>
      <div class="modal_mask" @click="close"></div>
  </div>
</template>
<script>
import {XIcon} from "@zhuowenli/vue-feather-icons";

export default {
  name: "Modal",
  components: {
    XIcon,
  },
  props: {
    width: {
      type: String,
      default: "100%",
    },
    maxWidth: {
      type: String,
      default: "400px",
    },
    minWidth: {
      type: String,
      default: "200px",
    },
    display: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "这是对话框内容",
    }
  },
  data() {
    return {
      show: true
    };
  },
  methods: {
    close(){
      this.show == false ? this.show = true :  this.show = false
    }
  },
  computed: {
  },
};
</script>
<style lang="scss">
.modal {
    position: fixed;
    z-index: 6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.modal_mask {
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}
.modal_container {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    font-size: 14px;
    color: var(--grey_9);
    transform: translate(-50%, -75%);
    background-color: var(--white);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: inset 0 0 0 1px var(--grey_0);
}
.modal_header {
    padding: 0 20px;
    width: 100%;
    height: 45px;
    box-shadow: inset 0 -1px 0 var(--grey_0);
    display: flex;
    justify-content: space-between;
    > .title {
        line-height: 45px;
    }
    > .close_button {
      margin-right: -16px;
      transition: color 0.2s;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      color: var(--grey_3);
      &:hover {
        color: var(--main_5);
      }
      &:active {
        color: var(--main_6);
      }
    }
}
.modal_body {
  padding: 20px;
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px 20px 20px;
  button + button {
    margin-left: 16px;
  }
}

// mobile
@media screen and (max-width: 720px) {
  .modal_container {
    width: 80% !important;
  }
}
</style>