<template>
  <div class="theme_switch" @click="switchTheme">
    <SunIcon size="1.2x" v-show="light == false"></SunIcon>
    <MoonIcon size="1.2x" v-show="light"></MoonIcon>
  </div>
</template>
<script>
import { SunIcon, MoonIcon } from "@zhuowenli/vue-feather-icons";

export default {
  name: "ThemeSwitch",
  components: {
    SunIcon,
    MoonIcon,
  },
  data() {
    return {
      light: false,
    };
  },
  mounted() {
    this.$nextTick(function () {
      if (localStorage.getItem("lisa_theme")) {
        localStorage.getItem("lisa_theme") == "light"
          ? (document.body.className = "", this.light = true)
          : (document.body.className = "mirror", this.light = false);
      } else {
        this.light == true
          ? (document.body.className = "")
          : (document.body.className = "mirror");
      }
    });
  },
  methods: {
    switchTheme() {
      if (document.body.className == "") {
        document.body.className = "mirror";
        localStorage.setItem("lisa_theme", "mirror");
        this.light = false;
      } else {
        document.body.className = "";
        localStorage.setItem("lisa_theme", "light");
        this.light = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.theme_switch {
  transition: 0.2s opacity;
  cursor: pointer;
  position: fixed;
  z-index: 9;
  opacity: 0.9;
  right: 40px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey_8);
  color: var(--white);
  &:hover {
    opacity: 1;
  }
}
// mobile
@media screen and (max-width: 720px) {
  .theme_switch {
    right: 20px;
    bottom: 20px;
  }
}
</style>