import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import BoxModel from '../views/BoxModel.vue'
import Display from '../views/Display.vue'
import Animation from '../views/Animation.vue'
import Border from '../views/Border.vue'
import Color from '../views/Color.vue'
import Filter from '../views/Filter.vue'
import FlexBox from '../views/FlexBox.vue'
import Font from '../views/Font.vue'
import Grid from '../views/Grid.vue'
import Position from '../views/Position.vue'
import ResponsiveLayout from '../views/ResponsiveLayout.vue'
import Selector from '../views/Selector.vue'
import SelfAdaptiveLayout from '../views/SelfAdaptiveLayout.vue'
import Shadow from '../views/Shadow.vue'
import TextAlign from '../views/TextAlign.vue'
import Outline from '../views/Outline.vue'
import TextColor from '../views/TextColor.vue'
import Word from '../views/Word.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/display',
    name: 'Display',
    component: Display
  },
  {
    path: '/box_model',
    name: 'Box_model',
    component: BoxModel
  },
  {
    path: '/animation',
    name: 'Animation',
    component: Animation
  },
  {
    path: '/border',
    name: 'Border',
    component: Border
  },
  {
    path: '/color',
    name: 'Color',
    component: Color
  },
  {
    path: '/filter',
    name: 'Filter',
    component: Filter
  },
  {
    path: '/flex_box',
    name: 'FlexBox',
    component: FlexBox
  },
  {
    path: '/font',
    name: 'Font',
    component: Font
  },
  {
    path: '/grid',
    name: 'Grid',
    component: Grid
  },
  {
    path: '/outline',
    name: 'Outline',
    component: Outline
  },
  {
    path: '/position',
    name: 'Position',
    component: Position
  },
  {
    path: '/responsive_layout',
    name: 'ResponsiveLayout',
    component: ResponsiveLayout
  },
  {
    path: '/selector',
    name: 'Selector',
    component: Selector
  },
  {
    path: '/self_adaptive_layout',
    name: 'SelfAdaptiveLayout',
    component: SelfAdaptiveLayout
  },
  {
    path: '/shadow',
    name: 'Shadow',
    component: Shadow
  },
  {
    path: '/text_align',
    name: 'TextAlign',
    component: TextAlign
  },
  {
    path: '/text_color',
    name: 'TextColor',
    component: TextColor
  },
  {
    path: '/word',
    name: 'Word',
    component: Word
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
