<template>
  <div class="body">
    <h2>👨‍💻</h2>
    <h1>作者正在马不停蹄的更新</h1>
  </div>
</template>
<style scoped>
  .body {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
  }
  h1 {
    margin: 0;
    color: var(--grey_2);
  }
  h2 {
    font-size: 60px;
    margin: 0;
  }
</style>