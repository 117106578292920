<template>
  <div>
    <!-- content -->
    <h1>
      <po style="font-size: 42px">👋 seeasas</po> - 交互式 CSS 学习站点（Beta）
    </h1>
    <label>在线编辑，实时预览，轻松学习</label>
    <br />
    <br />
    <h3>从这里 <a href="/#/display">开始学习</a></h3>
    <!-- coding -->
    <Coding
      :html="html_2"
      :css="css_2"
      :height="700"
      :topLeftPercent="60"
      :topPercent="50"
    ></Coding>
  </div>
</template>

<script>
import Coding from "@/components/Coding";

export default {
  name: "Home",
  components: {
    Coding,
  },
  data() {
    return {
      html_2: `<div class="stage">
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
    <div class="layer"></div>
</div>`,
      css_2: `body {
    min-height: 450px;
    height: 100vh;
    margin: 0;
    background: radial-gradient(circle, #666, #333, #000);
    font-family: sans-serif;
}

.stage {
    height: 300px;
    width: 500px;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    perspective: 9999px;
    transform-style: preserve-3d;
}

.layer {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    animation: motion 5s infinite alternate ease-in-out -7.5s;
    animation-fill-mode: forwards;
    transform: rotateY(40deg) rotateX(33deg) translateZ(0);
}

.layer:after {
    font: sans-serif;
    font-size: 100px;
    content: 'Hello, World!';
    white-space: pre;
    text-align: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50px;
    color: #f5f5f5;
    letter-spacing: -2px;
    text-shadow: 4px 0 10px rgba(0, 0, 0, 0.1);
}

.layer:nth-child(1):after {
    transform: translateZ(0px);
}

.layer:nth-child(2):after {
    transform: translateZ(-1.5px);
}

.layer:nth-child(3):after {
    transform: translateZ(-3px);
}

.layer:nth-child(4):after {
    transform: translateZ(-4.5px);
}

.layer:nth-child(5):after {
    transform: translateZ(-6px);
}

.layer:nth-child(6):after {
    transform: translateZ(-7.5px);
}

.layer:nth-child(7):after {
    transform: translateZ(-9px);
}

.layer:nth-child(8):after {
    transform: translateZ(-10.5px);
}

.layer:nth-child(9):after {
    transform: translateZ(-12px);
}

.layer:nth-child(10):after {
    transform: translateZ(-13.5px);
}

.layer:nth-child(11):after {
    transform: translateZ(-15px);
}

.layer:nth-child(12):after {
    transform: translateZ(-16.5px);
}

.layer:nth-child(13):after {
    transform: translateZ(-18px);
}

.layer:nth-child(14):after {
    transform: translateZ(-19.5px);
}

.layer:nth-child(15):after {
    transform: translateZ(-21px);
}

.layer:nth-child(16):after {
    transform: translateZ(-22.5px);
}

.layer:nth-child(17):after {
    transform: translateZ(-24px);
}

.layer:nth-child(18):after {
    transform: translateZ(-25.5px);
}

.layer:nth-child(19):after {
    transform: translateZ(-27px);
}

.layer:nth-child(20):after {
    transform: translateZ(-28.5px);
}

.layer:nth-child(n+10):after {
    -webkit-text-stroke: 3px rgba(0, 0, 0, 0.25);
}

.layer:nth-child(n+11):after {
    -webkit-text-stroke: 15px #999;
    text-shadow: 6px 0 1px rgba(0, 0, 0, 0.1), 5px 5px 1px rgba(0, 0, 0, 0.1), 0 6px 1px rgba(0, 0, 0, 0.2);
}

.layer:nth-child(n+12):after {
    -webkit-text-stroke: 15px #777;
}

.layer:last-child:after {
    -webkit-text-stroke: 17px rgba(0, 0, 0, 0.1);
}

.layer:first-child:after {
    color: #fff;
    text-shadow: none;
}

@keyframes motion {
    100% {
        transform: rotateY(-40deg) rotateX(-43deg);
    }
}`,
    };
  },
};
</script>