<template>
  <div
    class="drager_col"
    ref="container"
    :style="{ width: width, height: height }"
  >
    <div class="drager_left" :style="{ width: left + '%' }">
      <div>
        <slot name="left"></slot>
      </div>
    </div>
    <div
      class="col_slider"
      @touchstart.passive="mobileDragCol"
      @mousedown="dragCol"
    ></div>
    <div class="drager_right" :style="{ width: 100 - left + '%' }">
      <div>
        <slot name="right"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DragerCol",
  props: {
    leftPercent: {
      type: Number,
      default: 50,
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "600px",
    },
  },
  data() {
    return {
      left: this.leftPercent,
      isDrag: false,
    };
  },
  methods: {
    // 左右滑动
    mobileDragCol(e) {
      e = e || window.event;
      e.stopPropagation();
      let oldPos = e.changedTouches[0].clientX;
      let oldPosPercent = this.left;
      let newPos = 0;
      let newPosPercent = 0;
      const containerWidth = this.$refs.container.offsetWidth;
      const vue = this;
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 手指移动和抬起时触发
      document.ontouchmove = sliderDrag;
      document.ontouchend = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now();
        e = e || window.event;
        e.stopPropagation();
        newPos = e.changedTouches[0].clientX;
        const maxWidth =
          parseFloat(((1 - 10 / containerWidth) * 100).toFixed(3)) + 0.001;
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerWidth) * 100).toFixed(3)
        );
        newPosPercent = oldPosPercent - movingDistancePercent;
        if (newPosPercent <= 0) {
          vue.left = 0;
        } else if (newPosPercent >= maxWidth) {
          vue.left = maxWidth;
        } else {
          vue.left = newPosPercent;
        }
      }
      // 抬起触发函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag);
        document.ontouchmove = null;
        document.ontouchend = null;
      }
    },
    dragCol(e) {
      e = e || window.event;
      e.preventDefault(); // 阻止表单触发默认事件，但在 touch 事件中因使用了 passive 被动触发，所以无法禁止默认事件
      e.stopPropagation(); // 阻止事件冒泡
      let oldPos = e.clientX; // 记录拖拽条的初始位置
      let oldPosPercent = this.left; // 拖拽条初始位置的百分比
      let newPos = 0; // 设置新位置
      let newPosPercent = 0; // 设置新位置百分比
      const containerWidth = this.$refs.container.offsetWidth;// 获取容器的最新宽度
      const vue = this; // 记录 vue 对象
      // 将子组件的数据-是否开始拖拽状态，传递给父组件,isDrag 是父组件中要绑定的函数
      this.isDrag = true;
      this.$emit('isDrag',this.isDrag);
      // 鼠标移动和抬起时触发
      document.onmousemove = sliderDrag;
      document.onmouseup = cancelSliderDrag;
      // 移动触发函数
      function sliderDrag(e) {
        // 函数性能节流，如果函数触发间隔小于 40 毫秒则不触发
        if (this.time && Date.now() - this.time < 40) return;
        this.time = Date.now(); // 记录新的时间，在下次触发中使用
        // 阻止默认事件和事件冒泡
        e = e || window.event;
        e.preventDefault();
        e.stopPropagation();
        newPos = e.clientX; // 获取新的位置
        const maxWidth =
          parseFloat(((1 - 10 / containerWidth) * 100).toFixed(3)) + 0.001; // 将拖拽条的宽度计算在内后得到能拖拽的最大宽度，因为小数可能除不尽，宁愿大 0.001 让宽度超过容器也不要撑不满漏出
        const movingDistancePercent = parseFloat(
          (((oldPos - newPos) / containerWidth) * 100).toFixed(3) // 计算鼠标的移动距离，因为是百分比所以保留 2 位小数
        );
        newPosPercent = oldPosPercent - movingDistancePercent; // 初始位置减去移动距离就是新的位置
        if (newPosPercent <= 0) {
          // 新的位置最小为 0
          vue.left = 0;
        } else if (newPosPercent >= maxWidth) {
          // 新的位置最大为之前计算的最大宽度
          vue.left = maxWidth;
        } else {
          vue.left = newPosPercent;
        }
      }
      // 取消移动和抬起鼠标事件所触发的函数
      function cancelSliderDrag() {
        vue.isDrag = false;
        vue.$emit('isNotDrag',vue.isDrag); // 将子组件的数据-是否开始拖拽状态，传递给父组件
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.drager_col {
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  > div {
    height: 100%;
  }
}
.col_slider {
  margin: 0 -10px;
  position: relative;
  transition: background-color 0.2s;
  cursor: col-resize;
  width: 20px;
  &:before {
    transition: background-color 0.2s;
    position: absolute;
    top: 50%;
    left: 6px;
    margin-top: -30px;
    content: "";
    display: block;
    width: 1px;
    height: 60px;
    background-color: var(--grey_3);
  }
  &:after {
    transition: background-color 0.2s;
    position: absolute;
    top: 50%;
    right: 6px;
    margin-top: -30px;
    content: "";
    display: block;
    width: 1px;
    height: 60px;
    background-color: var(--grey_3);
  }
  &:active,
  &:hover {
    background-color: var(--main_1);
    &:before,
    &:after {
      background-color: var(--main_6);
    }
  }
}
.drager_left {
  padding-right: 10px;
  div {
    height: 100%;
    overflow: hidden;
  }
}
.drager_right {
  padding-left: 10px;
  div {
    height: 100%;
    overflow: hidden;
  }
}
</style>