<template>
  <div :class="{open: isOpen}">
    <div class="menu_switch" @click="toggle">
      <MenuIcon size="1.2x"></MenuIcon>
      <XIcon size="1.2x"></XIcon>
    </div>
    <div class="side_menu">
      <ul>
        <li v-for="(item, name) in tree" :key="item.index">
          <!-- 二级页面 -->
          <div
            v-if="item.child"
            :class="{ show: item.show }"
            @click="toggleChild(name)"
          >
            <span>{{ name }}</span>
            <ChevronDownIcon size="1x"></ChevronDownIcon>
          </div>
          <ul v-if="item.child">
            <li
              v-for="(item_item, name_name) in item.child"
              :key="item_item.index"
            >
              <router-link :to="item_item.path" v-if="item_item.path">{{
                name_name
              }}</router-link>
              <!-- 三级页面 -->
              <div
                v-if="item_item.child"
                :class="{ show: item_item.show }"
                @click="toggleChild(name, name_name)"
              >
                <span>{{ name_name }}</span>
                <ChevronDownIcon size="1x"></ChevronDownIcon>
              </div>
              <ul v-if="item_item.child">
                <li
                  v-for="(item_item_item, name_name_name) in item_item.child"
                  :key="item_item_item.index"
                >
                  <router-link :to="item_item_item.path">{{
                    name_name_name
                  }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
          <!-- 一级页面 -->
          <router-link :to="item.path" v-if="!item.child">{{ name }}</router-link>
        </li>
      </ul>
    </div>
    <div class="side_menu_mask" @click="toggle"></div>
  </div>
</template>
<script>
import { ChevronDownIcon, MenuIcon, XIcon } from "@zhuowenli/vue-feather-icons";
export default {
  name: "SideMenu",
  components: {
    ChevronDownIcon,
    MenuIcon,
    XIcon
  },
  props: {
    tree: {},
  },
  data() {
    return {
      isShowList: this.tree,
      isOpen: false,
    };
  },
  methods: {
    // 点击菜单显示或隐藏子菜单，通过 isShowList 记录每个父菜单的展开情况，而后点击修改每个记录状态的数据
    toggleChild(name, name_name) {
      if (!name_name) {
        this.isShowList[name].show == true
          ? (this.isShowList[name].show = false)
          : (this.isShowList[name].show = true);
      } else {
        this.isShowList[name].child[name_name].show == true
          ? (this.isShowList[name].child[name_name].show = false)
          : (this.isShowList[name].child[name_name].show = true);
      }
    },
    toggle(){
      this.isOpen = this.isOpen == true ? false : true
    }
  },
  watch: {
    $route: {
      // 控制当前路由下的菜单自动打开
      handler: function (router) {
        for (const key in this.isShowList) {
          // 二级页面
          for (const key_key in this.isShowList[key].child) {
            if (this.isShowList[key].child[key_key].path === router.path) {
              this.isShowList[key].show = true;
            }
            // 三级页面
            for (const key_key_key in this.isShowList[key].child[key_key]
              .child) {
              if (
                this.isShowList[key].child[key_key].child[key_key_key].path ===
                router.path
              ) {
                this.isShowList[key].show = true;
                this.isShowList[key].child[key_key].show = true;
              }
            }
          }
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
.side_menu {
  transition: left 0.3s;
  overflow: auto;
  user-select: none;
  height: 100vh;
  line-height: 40px;
  width: 190px;
  padding: 0 20px;
  font-size: 14px;
  color: var(--grey_8);
  text-align: left;
  background-color: var(--white);
  border-right: 1px solid var(--grey_0);
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
}
.side_menu {
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  a {
    transition: color 0.26s, background-color 0.26s;
    margin: 0 -20px;
    padding-left: 20px;
    display: block;
    &:hover {
      color: var(--main_5);
    }
  }
  > ul {
    > li {
      font-weight: bold;
      li {
        font-weight: normal;
      }
    }
    > li + li {
      position: relative;
      padding-top: 4px;
      &:after {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: calc(100% + 40px);
        height: 1px;
        background-color: var(--grey_0);
      }
    }
  }
  ul {
    ul {
      text-indent: 20px;
      ul {
        text-indent: 40px;
      }
    }
  }
  li {
    line-height: inherit;
  }
  > ul div {
    transition: color 0.26s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin: 0 -20px;
    & + ul {
      display: none;
    }
    &:hover {
      color: var(--main_5);
      svg {
        color: var(--main_5);
      }
    }
    svg {
      color: var(--grey_2);
      transition: transform 0.26s, color 0.26s;
    }
  }
}

.show {
  svg {
    transform: rotateZ(180deg);
  }
  & + ul {
    display: block !important;
  }
}
a.router-link-exact-active {
  color: var(--main_5);
  background-color: var(--main_1);
}
.menu_switch {
  display: none;
  cursor: pointer;
  position: fixed;
  z-index: 9;
  opacity: 0.9;
  left: 40px;
  bottom: 40px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: var(--grey_8);
  color: var(--white);
  > svg + svg {
    display: none;
  }
}
.side_menu_mask {
  display: none;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.8);
}

// mobile
@media screen and (max-width: 720px) {
  .open {
    .menu_switch {
      svg {
        display: none;
      }
      svg + svg {
        display: block;
      }
    }
    .side_menu {
      left: 0;
    }
    .side_menu_mask {
      display: block;
    }
  }
  .side_menu {
    left: -190px;
    z-index: 5;
  }
  .menu_switch {
    display: flex;
    left: 20px;
    bottom: 20px;
  }
}
</style>