<template>
  <div>
    <!-- pagination -->
    <Pagination pre="首页" next="CSS 盒模型" nextPath="/box_model"></Pagination>
    <!-- content -->
    <h1>元素显示方式</h1>
    <label>相关属性：<code>display</code></label>
    <p></p>
    <br />
    <br />
    <p>
      <code>display</code>
      中文直译是“显示”，其核心作用是通过控制元素的显示方式，从而影响元素的排列和布局，并非只是显示与否如此简单，理解了它你就能理解网页布局的基础原理。元素的显示方式主要分以下几种：
    </p>
    <ul>
      <li>块级元素 - <code>display: block</code></li>
      <li>内联元素 - <code>display: inline</code></li>
      <li>内联块元素 - <code>display: inline-block</code></li>
    </ul>
    <p>除了以上三种，<code>display</code> 还有例如 <a href="/#/flexbox">flex</a> 和 <a href="/#/grid">grid</a> 等高级属性值，你可以前往 <po>布局</po> 章节深入了解。</p>

    <h2>块级元素 </h2>
    <code>display: block</code>
    <h3><po>1. 定义</po></h3>
    <p>块级元素是二维的，它可设置高度和宽度，即它是一个以方块状呈现的元素。</p>
    <label>尝试修改 .el 的高度 height 和宽度 width， 从而改变元素的大小</label>
    <Coding
      :html="html_1"
      :css="css_1"
    ></Coding>
    <br />

    <h3><po>2. 默认宽度</po></h3>
    <p>在未设置宽度的情况下，块级元素自身的默认宽度会跟随父元素的宽度，也就是元素会水平<po> 撑满 </po>整个父容器。</p>
    <label>尝试拖拽预览窗口右侧的拖拽条“||”改变视窗宽度，你将看到元素的宽度也会随之改变（如果你试着拖拽上一个案例中的预览窗口，你将看到截然不同的效果，因为在上一个案例中，元素的宽度被设为了一个固定值）</label>
    <Coding
      :html="html_2"
      :css="css_2"
    ></Coding>
    <br />

    <h3><po>3. 默认高度</po></h3>
    <p>在未设置高度的情况下，块级元素自身的默认高度会跟随子元素的高度，若无子元素则高度为 0。</p>
    <label>尝试修改子元素 .el_2 的高度 height， 从而改变父元素的大小</label>
    <Coding
      :html="html_3"
      :css="css_3"
      :topLeftPercent="40"
    ></Coding>
    <br />

    <h3><po>4. 排列方式</po></h3>
    <p>块级元素无论自身宽度多少，都会独占一行，也就是其之后的元素都会另起一行向下排列。</p>
    <label>如下案例中你会发现不管如何设置块级元素的宽度，每个块级元素总是独占一行。</label>
    <Coding
      :html="html_4"
      :css="css_4"
    ></Coding>
    <br />

    <h3><po>5. 内外边距</po></h3>
    <p>块级元素可设置自身的内边距和外边距，想了解更多有关内外边距的要点，可前往章节 <a href="/#/box_model">CSS 盒模型</a> 了解更多</p>
    <label>尝试修改元素 .el_1 的 内边距 padding 和外边距 margin</label>
    <Coding
      :html="html_5"
      :css="css_5"
      :height="560"
      :topPercent="50"
    ></Coding>
    <br />

    <!-- 内联元素 -->
    <h2>内联元素</h2>
    <code>display: inline</code>
    <h3><po>1. 定义</po></h3>
    <p>内联元素是一维的，自身 <po>没有宽高</po>，其本质就是一段可穿插图片的 <po>富文本</po>，多个内联元素在一起会 <po>水平横向排列</po>，即它是一个以线性呈现的元素。</p>
    <label>尝试拖拽预览窗口右侧的拖拽条“||”改变视窗宽度，你将看到在宽度不足的情况下，元素会首尾相连向下堆叠</label>
    <Coding
      :html="html_6"
      :css="css_6"
      :height="360"
      :topPercent="70"
    ></Coding>
    <br />

    <h3><po>2. 内外边距</po></h3>
    <p>与块级元素不同，内联元素通常只有左右内边距和左右外边距。</p>
    <Coding
      :html="html_9"
      :css="css_9"
      :height="400"
      :topPercent="70"
    ></Coding>
    <br />

    <h3><po>3. 对齐方式</po></h3>
    <p>因为内联元素本质是一段文本，所以它有居左对齐，居右对齐、居中对齐和多行左右顶边对齐四种对齐方式，它所对齐的参照物是父元素，所以对齐属性是赋予父元素的，生效对象是子元素。</p>
    <label>尝试拖拽预览窗口右侧的拖拽条“||”改变视窗宽度，你将看到文本在不同宽度下的变化</label>
    <Coding
      :html="html_7"
      :css="css_7"
    ></Coding>
    <br />

    <h2>内联块元素</h2>
    <code>display: inline-block</code>
    <h3><po>1. 定义</po></h3>
    <p>顾名思义，内联块元素兼具内联元素和块级元素的特性，其特别之处是它能以块状呈现并横向排列，</p>
    <Coding
      :html="html_8"
      :css="css_8"
      :height="400"
      :topPercent="60"
    ></Coding>
    <br />

    <h3><po>2. 技巧</po></h3>
    <p>在页面布局中，<code>display: inline-block</code> 经常被用在横向布局的场景中，但细心的你会发现，紧邻的元素之间总存在些许间隙，这是一个令人头疼的问题，之所以出现这个问题是因为 <code>inline</code> 内联属性会把 HTML 元素当作是一段文本，而如果文本与文本之间存在空格或折行的话，浏览器会把它渲染成一个间隙，同理在内联块属性中也是如此，要规避这个问题，我们可以把 HTML <po>闭合标签</po> 之间的折行和空格删掉。</p>
    <label>如下案例中你会发现，它的代码与上一个案例中的代码是一样的，仅仅是去掉了 HTML 中的折行，就让元素紧密相连，你可以试着在 HTML 中加上一些折行或空格复现问题</label>
    <Coding
      :html="html_10"
      :css="css_10"
      :height="400"
      :topPercent="60"
    ></Coding>
    <br />

    <!-- pagination -->
    <br />
    <Pagination pre="首页" next="CSS 盒模型" nextPath="/box_model"></Pagination>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Coding from "@/components/Coding";

export default {
  name: "Display",
  components: {
    Pagination,
    Coding,
  },
  data() {
    return {
      html_10: `<div class="el"></div><div class="el"></div><div class="el"></div>












<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        color: white;
        background-color: #6495ed;
        position: relative;
    }
    .el:nth-child(even) {
      background-color: #ed649d;
    }
    .el:before {
        content:"内联块元素";
        text-align: center;
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>`,
      css_10: `.el {
    display: inline-block;
    width: 100px;
    height: 100px;
}`,
      html_9: `<div class="el_1">
    <div class="el"></div>
</div>











<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        color: white;
        background-color: #ed649d;
    }
    .el:before {
        content: "内联元素";
        background-color: #6495ed;
    }
    .el_1 {
        display: inline-block;
        background-color: #ebb537;
    }
</style>`,
      css_9: `.el {
    display: inline;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
    margin-right: 20px;
}`,
      html_8: `<div class="el"></div>
<div class="el"></div>
<div class="el"></div>










<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        color: white;
        background-color: #6495ed;
        position: relative;
    }
    .el:nth-child(even) {
      background-color: #ed649d;
    }
    .el:before {
        content:"内联块元素";
        text-align: center;
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>`,
      css_8: `.el {
    display: inline-block;
    width: 100px;
    height: 100px;
}`,
      html_7: `<div class="el_1">
    <div class="el">这是一个左对齐的内联元素</div>
</div>
<div class="el_2">
    <div class="el">这是一个右对齐的内联元素</div>
</div>
<div class="el_3">
    <div class="el">这是一个居中对齐的内联元素</div>
</div>
<div class="el_4">
    <div class="el">这是一个多行左右顶边对齐的内联元素，这是一个多行左右顶边对齐的内联元素，这是一个多行左右顶边对齐的内联元素，这是一个多行左右顶边对齐的内联元素，这是一个多行左右顶边对齐的内联元素</div>
</div>










<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        display: inline;
        color: white;
        background-color: #6495ed;
    }
    div+div {
        margin-top: 10px;
    }
</style>`,
      css_7: `.el_1 {
    text-align: left;
}
.el_2 {
    text-align: right;
}
.el_3 {
    text-align: center;
}
.el_4 {
    text-align: justify;
}`,
      html_6: `<div class="el">这是内联元素</div>
<div class="el">这是内联元素</div>
<div class="el">这是内联元素</div>
<div class="el">这是内联元素</div>
<div class="el">这是内联元素</div>
<div class="el">这是内联元素</div>










<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        color: white;
        background-color: #6495ed;
    }
</style>`,
      css_6: `.el {
    display: inline;
}`,
      html_5: `<div class="el_2">
    <div class="el_1"></div>
</div>












<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el_1 {
        color: white;
        text-align: center;
        background-color: #ed649d;
    }
    .el_1:before {
        content: "块级元素";
        line-height: 70px;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #6495ed;
    }
    .el_1:after {
        line-height: 30px;
        content: "内边距";
        display: block;
        font-size: 13px;
    }
    .el_2 {
        display: inline-block;
        background-color: #ebb537;
    }
    .el_2:after {
        color: white;
        text-align: center;
        line-height: 30px;
        margin-top: -30px;
        content: "外边距";
        display: block;
        font-size: 13px;
    }
</style>`,
      css_5: `.el_1 {
    display: block;
    width: 130px;
    height: 70px;
    padding: 30px;
    margin: 30px;
}`,
      html_4: `<div class="el_1"></div>
<div class="el_2"></div>
















<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el_1 {
        background-color: #6495ed;
        color: white;
        text-align: center;
        position: relative;
    }
    .el_1:before {
        content: "第一个块级元素";
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .el_2 {
        margin-top: 10px;
        background-color: #ed649d;
        color: white;
        text-align: center;
        position: relative;
    }
    .el_2:before {
        content: "第二个块级元素";
        display: block;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>`,
      css_4: `.el_1 {
    display: block;
    width: 200px;
    height: 70px;
}
.el_2 {
    display: block;
    width: 200px;
    height: 70px;
}`,
      html_1: `<div class="el"></div>











<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        background-color: #6495ed;
        color: white;
        position: relative;
    }
    .el:before {
        content: "这是一个块级元素";
        text-align: center;
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    body {
      margin: 0;
    }
</style>`,
      css_1: `.el {
    display: block;
    height: 150px;
    width: 200px;
}`,
      html_2: `<div class="el"></div>










<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el {
        background-color: #6495ed;
        color: white;
        position: relative;
    }
    .el:before {
        content: "这是一个块级元素";
        text-align: center;
        width: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    body {
      margin: 0;
    }
</style>`,
      css_2: `.el {
    display: block;
    height: 150px;
}`,
      html_3: `<div class="el_1">
    <div class="el_2"></div>
</div>












<!-- 以下为辅助阅读样式，请忽略 -->
<style>
    .el_1 {
        background-color: #6495ed;
        color: white;
        position: relative;
        padding: 10px;
    }
    .el_1:before {
        content: "父元素";
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
    }
    .el_2 {
        width: 100px;
        background-color: #ed649d;
        color: white;
        position: relative;
        margin: 0 auto;
    }
    .el_2:before {
        content: "子元素";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    body {
      margin: 0;
    }
</style>`,
      css_3: `.el_1 {
    display: block;
}
.el_2 {
    height: 100px;
}`,
    };
  },
};
</script>
<style lang="scss">
</style>